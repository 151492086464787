.notifications-container {
  .card {
    border-radius: 12px;
    border: none;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);

    .card-body {
      padding: 1.5rem;
    }
  }

  .header-filters {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);

    .search-box {
      .form-control {
        border-radius: 8px;
        padding-left: 2.5rem;
        height: 38px;

        &:focus {
          border-color: var(--bs-primary);
          box-shadow: none;
        }
      }

      .search-icon {
        position: absolute;
        left: 0.875rem;
        top: 50%;
        transform: translateY(-50%);
        color: #6c757d;
        font-size: 1rem;
        z-index: 4;
      }
    }

    .filter-select {
      border-radius: 8px;
      height: 38px;
      min-width: 180px;
      background-position: right 0.75rem center;
    }
  }

  .notification-item {
    padding: 1.25rem;
    border-radius: 10px;
    margin-bottom: 0.5rem;
    transition: all 0.2s ease;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.05);

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
    }

    &.unread {
      background: rgba(var(--bs-primary-rgb), 0.03);
      border-left: 3px solid var(--bs-primary);
    }

    .notification-icon {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      font-size: 1.4rem;
    }

    .notification-content {
      .title-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 0.5rem;

        h6 {
          font-weight: 600;
          font-size: 0.95rem;
          margin: 0;
        }
      }

      .message {
        color: #6c757d;
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
      }

      .meta-info {
        font-size: 0.75rem;
        color: #999;
      }
    }
  }

  .status-badge {
    padding: 0.35rem 0.75rem;
    border-radius: 6px;
    font-size: 0.75rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.375rem;

    i {
      font-size: 0.875rem;
    }

    &.badge-warning {
      background-color: rgba(255, 193, 7, 0.15);
      color: #ffc107;
    }

    &.badge-success {
      background-color: rgba(25, 135, 84, 0.15);
      color: #198754;
    }

    &.badge-danger {
      background-color: rgba(220, 53, 69, 0.15);
      color: #dc3545;
    }

    &.badge-info {
      background-color: rgba(13, 110, 253, 0.15);
      color: #0d6efd;
    }
  }
}

// Modal Styles
.notification-modal {
  .modal-content {
    border: none;
    border-radius: 16px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    background: #f8f9fa;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 16px 16px 0 0;

    .modal-title {
      display: flex;
      align-items: center;
      gap: 1rem;

      .icon-wrapper {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        font-size: 1.5rem;
      }

      .title-content {
        h5 {
          margin-bottom: 0.25rem;
          font-weight: 600;
        }

        .subtitle {
          color: #6c757d;
          font-size: 0.875rem;
        }
      }
    }
  }

  .modal-body {
    padding: 1.5rem;

    .info-section {
      background: #f8f9fa;
      border-radius: 12px;
      padding: 1.25rem;
      margin-bottom: 1.5rem;

      .section-title {
        font-size: 0.875rem;
        text-transform: uppercase;
        color: #495057;
        font-weight: 600;
        margin-bottom: 1rem;
        letter-spacing: 0.5px;
      }

      .detail-row {
        display: flex;
        margin-bottom: 0.75rem;
        align-items: flex-start;

        .detail-label {
          width: 140px;
          font-size: 0.813rem;
          color: #6c757d;
          font-weight: 500;
        }

        .detail-value {
          flex: 1;
          font-size: 0.875rem;
          color: #212529;
          font-weight: 500;

          &.highlight {
            color: var(--bs-primary);
          }
        }
      }
    }
  }

  .modal-footer {
    padding: 1rem 1.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.05);

    .btn {
      padding: 0.5rem 1.25rem;
      font-weight: 500;
      border-radius: 8px;
    }
  }
}
