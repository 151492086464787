.quick-access-card {
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background-color: white;
    /*max-width: 600px;*/
    margin: 0 auto;
    padding: 0.75rem;
    margin-bottom: 8px;
}

.quick-access-title {
    font-size: 1rem;
    font-weight: 500;
    color: #2d3748;
    margin-bottom: 0.5rem;
    text-align: center;
}

.quick-access-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.quick-access-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    color: #4a5568;
    text-decoration: none;
    transition: background-color 0.2s;
    border-radius: 0.25rem;
}

.quick-access-item:hover {
    background-color: #f7fafc;
}

.quick-access-item svg {
    margin-right: 0.5rem;
}

.quick-access-item span {
    font-size: 0.875rem;
}
