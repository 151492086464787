.header-item.noti-icon {
    padding: 0.5rem;
    transition: all 0.2s;
}

.header-item.noti-icon:hover {
    background: rgba(var(--bs-primary-rgb), 0.05);
    border-radius: 8px;
}

.notification-bell-wrapper {
    position: relative;
    display: inline-block;
}

.notification-indicator {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #dc3545;
    border: 2px solid #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.15);
}

.notification-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 10px;
    font-size: 1.2rem;
}

.notification-item {
    transition: all 0.2s;
}

.notification-item:hover {
    background-color: rgba(var(--bs-primary-rgb), 0.02);
}

.notification-badge {
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.3px;
}

.badge-warning {
    background-color: rgba(255, 193, 7, 0.15);
    color: #ffc107;
}

.badge-success {
    background-color: rgba(25, 135, 84, 0.15);
    color: #198754;
}

.badge-danger {
    background-color: rgba(220, 53, 69, 0.15);
    color: #dc3545;
}

.badge-info {
    background-color: rgba(13, 110, 253, 0.15);
    color: #0d6efd;
}

.notification-modal .modal-content {
    border: none;
    border-radius: 16px;
    box-shadow: 0 10px 40px rgba(0,0,0,0.1);
}

.modal-header-custom {
    background-color: #f8f9fa;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    border-radius: 16px 16px 0 0;
    padding: 1.5rem;
}

.modal-body-custom {
    padding: 1.5rem;
}

.notification-icon-lg {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-size: 1.5rem;
}

.modal-title-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.status-indicator {
    display: inline-flex;
    align-items: center;
    padding: 0.35rem 0.75rem;
    border-radius: 6px;
    font-size: 0.813rem;
    font-weight: 500;
}

.content-section {
    background: #fff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.04);
}

.section-title {
    color: #344767;
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 1.25rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.info-group {
    margin-bottom: 1.25rem;
}

.info-label {
    font-size: 0.75rem;
    color: #6c757d;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.info-value {
    font-size: 0.875rem;
    color: #344767;
    margin-bottom: 0;
    font-weight: 500;
}

.info-value.highlight {
    color: var(--bs-primary);
    font-weight: 600;
}

.modal-footer-custom {
    border-top: 1px solid rgba(0,0,0,0.05);
    padding-top: 1.5rem;
    display: flex;
    justify-content: flex-end;
}

.dropdown-menu {
    margin-top: 0.5rem !important;
    box-shadow: 0 10px 40px rgba(0,0,0,0.08);
    border: 1px solid rgba(0,0,0,0.05);
    border-radius: 12px;
    min-width: 360px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.text-muted {
    color: #6c757d !important;
}

.border-light {
    border-color: rgba(0,0,0,0.05) !important;
}

.bg-warning-subtle {
    background-color: rgba(255, 193, 7, 0.15);
}

.bg-success-subtle {
    background-color: rgba(25, 135, 84, 0.15);
}

.bg-primary-subtle {
    background-color: rgba(13, 110, 253, 0.15);
}

.bg-danger-subtle {
    background-color: rgba(220, 53, 69, 0.15);
}

.min-w-0 {
    min-width: 0;
}

@media (max-width: 768px) {
    .modal-title-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .dropdown-menu {
        min-width: 300px;
    }

    .notification-icon-lg {
        width: 40px;
        height: 40px;
        font-size: 1.25rem;
    }

    .content-section {
        padding: 1rem;
    }
}
